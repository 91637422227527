import React from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import ToTop from "../components/to-top/to-top";

const ReactStorybook = () => {
  return (
    <Layout>
      <div className="details case-study rsb-pl">
        <SEO title=".Gov Website Redesign" />

        <div className="fixed-nav">
          <a className="home-link fixed-link" href="/">
            <img className="arrow-left" alt="arrow-left" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
            <span>home</span>
          </a>
          <a className="next-link fixed-link" href="/fareshare">
            <span>next</span>
            <img className="arrow-right" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
          </a>
        </div>
        <ToTop></ToTop>
        <section className="wrapper-container">
          <div className="row">
            <div className="col">
              <div className="vert-center">
              <h3>Streamlining future development with a pattern library.</h3>
              <p>
              Utilized HTML/CSS/Sass, JavaScript, React, Storybook.js, Jest & Enzyme.
              </p>
              <p><a rel="noopener noreferrer" href="https://my-storybook-5566d.firebaseapp.com/" title="development link" target="_blank">development link</a></p>
              
              </div>
            </div>
            <div className="col with-split-hero">
              <div className="hero-top">
                  <img alt="react" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739360509/React-icon_1_hsw2cm.png"></img>
              </div>
              <div className="hero-bot">
              <img alt="storybook js" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739360509/logo-storybook-default_1_vgoyxz.png"></img>
              </div>
            </div>
          </div>

        </section>

        <section className="wrapper-container image-container">
          {/* storybook */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739110252/storybook-screen_n8z3mc.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739110252/storybook-screen_n8z3mc.png" ></img></a>
        </section>

        <section className="wrapper-container image-container">
          {/* code */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739110256/code-screen_akc0so.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739110256/code-screen_akc0so.png" ></img></a>
        </section>

        <div className="project-bottom">
          <Link to="/fareshare" title="To a carpooling mobile app">
            <span>a carpooling mobile app</span>
            <img className="arrow-right-long" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/arrow-right-long-light_1_qchclz.png"></img>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ReactStorybook;